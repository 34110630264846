import { toASCII } from "punycode";
import { Company } from "../interface/company";
import { AddressUtilities } from "./address-utilities";
import { Utilities } from "./utilities";

export class CompanyUtilities extends Utilities {

  static changed(a: Company, b: Company): boolean {
    return !this.equalString(a.socialName, b.socialName)
      || !this.equalUnmasked(a.cnpj, b.cnpj)
      || !this.equalUnmasked(a.phone, b.phone)
      || !this.equalUnmasked(a.phone2, b.phone2)
      || !this.equalString(a.fancyName, b.fancyName)
      || !this.equalString(a.stateRegistration, b.stateRegistration)
      || !this.equalString(a.crt, b.crt)
      || !this.equalString(a.email, b.email)
      || !this.equalString(a.site, b.site)
      || !this.equalString(a.IM, b.IM)
      || !this.equalString(a.munIncidISS, b.munIncidISS)
      || !this.equalString(a.verificaRPS, b.verificaRPS)
      || !this.equalString(a.tributMunicipio, b.tributMunicipio)
      || !this.equalString(a.accessKeyInvoice, b.accessKeyInvoice)
      || !this.equalString(a.cnaeCode, b.cnaeCode)
      || !this.equalString(a.lastNSU, b.lastNSU)

      || !this.equalString(a.brand, b.brand)
      || !this.equalString(a.tokenWhats, b.tokenWhats)

      || a.exigibilidade != b.exigibilidade
      || a.numberInvoice != b.numberInvoice
      || a.numberInvoiceNFC != b.numberInvoiceNFC
      || a.numberInvoiceNFS != b.numberInvoiceNFS
      || a.numberGerencial != b.numberGerencial
      || a.serieInvoice != b.serieInvoice
      || a.serieInvoiceNFC != b.serieInvoiceNFC
      || a.serieInvoiceNFS != b.serieInvoiceNFS
      || a.serieGerencial != b.serieGerencial
      || a.regEspTrib != b.regEspTrib
      || AddressUtilities.changed(a.address, b.address)
      || a.companyLogo != b.companyLogo
      || a.associateLogo != b.associateLogo
      || a.migrateIntegrated != b.migrateIntegrated
      || a.controlStock != b.controlStock
      || a.ISS != b.ISS
      || a.patternNFSE != b.patternNFSE
      //|| a.makena != b.makena
      //|| a.tab != b.tab
  }

  static complyApp(company: Company): Company {
    company.fancyName = (company.fancyName || '').trim();
    company.crt = (company.crt);
    company.address = company.address || {};
    company.stateRegistration = (company.stateRegistration || '').trim();
    company.email = toASCII((company.email || '').trim());
    company.companyLogo = (company.companyLogo || '').trim();
    company.associateLogo = (company.associateLogo || '').trim();
    company.ISS = Number(company.ISS) || 0;
    return company;
  }

  static complyApi(company: Company): Company {
    let apiCompany = {};
    for (const key of Object.keys(company)) {
      let k: number = 1
      switch (company[key]) {
        case null:
        case undefined:
          break;
        default:
          apiCompany[key] = company[key];
          break;
      }
    }
    const nsu = company.lastNSU || "1";
    apiCompany["lastNSU"] = nsu.padStart(15, '0');
    apiCompany["migrateIntegrated"] = Boolean(company.migrateIntegrated);
    apiCompany["controlStock"] = Boolean(company.controlStock);
    apiCompany['ISS'] = Number(apiCompany['ISS']) || '0';
    delete apiCompany['companyLogoBlob'];
    delete apiCompany['companyLogoInput'];
    delete apiCompany['associationLogoBlob'];
    delete apiCompany['associationLogoInput'];
    return apiCompany
  }

}
